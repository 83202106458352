import findIndex from 'lodash/findIndex';
import { ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR, ROLE_PLANNER } from "../../utils/Roles";

class ProductionOrderGuard {
    canEdit( user ) {
        if ( findIndex( user.roles, i => i === ROLE_ADMIN || i === ROLE_SHIFT_SUPERVISOR ) !== -1 ) {
            return true;
        }

        return false;
    }

    canUnite(user) {
        if (findIndex(user.roles, i => i === ROLE_PLANNER || i === ROLE_SHIFT_SUPERVISOR) !== -1) {
            return true;
        }

        return false;
    }
}

const Guard = new ProductionOrderGuard();

export { Guard };
export default Guard;
