<template>
    <b-modal
        @hidden='hideModal()'
        scrollable
        ref='ProductionOrderHistoryModal'
        id='production-order-history-modal'
        :title='`История изменения заказа #` + orderId'
    >
        <b-table
            stipred
            :responsive="true"
            :items='history'
            :fields='fields'
            head-variant='dark'
            :no-local-sorting=true
        >
            <template #cell(diff)="data">{{ getDiff( data.index ) }}</template>

            <template #cell(updatedAt)="data">
                {{ moment( data.item.updated_at ).format( 'DD.MM.YYYY HH:mm' ) }}
            </template>
        </b-table>

        <template #modal-footer>
            <div class='w-100 text-right'>
                <b-button variant='danger' @click='hideModal()'>Закрыть</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import moment from 'moment';
    import clone from 'lodash/clone';

    export default {
        name: 'ProductionOrderHistoryModal',
        props: {
            value: { type: Array },
            orderId: { type: Number }
        },
        data() {
            return {
                fields: [
                    { key: 'id', label: '#' },
                    { key: 'count', label: 'Количество' },
                    { key: 'diff', label: 'Расхождения' },
                    { key: 'updatedAt', label: 'Дата изменения' }
                ],
                history: []
            };
        },
        watch: {
            value() {
                this.history = clone( this.value );

                if ( this.history.length > 0 ) {
                    this.$refs.ProductionOrderHistoryModal.show();
                    return null;
                }

                this.$refs.ProductionOrderHistoryModal.hide();
            }
        },
        methods: {
            moment,
            hideModal() {
                this.history = [];
                this.$emit( 'input', this.history );
            },
            getDiff( index ) {
                return index > 0 ? this.history[ index ].count - this.history[ index - 1 ].count : 0;
            }
        }
    }
</script>
