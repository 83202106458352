<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-4">
                    <date-time-picker
                        @is-hidden="compareToDate = compareToDateTmp"
                        :overlay="true"
                        locale='ru'
                        input-size='sm'
                        label='Дата'
                        id='salary_date_from_picker'

                        :max-date="maxDate"
                        :minute-interval="10"
                        :no-label="true"

                        format='YYYY-MM-DD HH:mm'
                        formatted='YYYY-MM-DD HH:mm'

                        v-model='compareToDateTmp'
                    ></date-time-picker>
                    </div>
                    <div class="col-1">
                        <button class="btn btn-success" @click="load(true)">
                            <i v-if="csvLoading" class="fas fa-spinner fa-spin"></i>
                            <i v-else class="fas fa-download"></i>
                            CSV
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <table class="table table-sm table-bordered">
                    <thead>
                    <tr>
                        <td>Номенклатура</td>
                        <td>{{compareToDate ? moment(compareToDate).format('DD.MM.YYYY HH:mm') : 'ближайшее изменение'}}</td>
                        <td>Текущее</td>
                        <td>Разница</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="loading">
                        <td colspan="4">
                            <div class="alert alert-info text-center mb-0"><i class="fas fa-spinner fa-spin"></i></div>
                        </td>
                    </tr>
                    <template v-else-if="items.length">
                        <tr v-for="item in items" :class="{'alert-danger': item.count < item.prev_count, 'alert-success': item.count > item.prev_count}">
                            <td>
                                {{item.nomenclature.name}}
                                <b-badge v-show="item.showAlternatable && item.alternatableCount > 0" variant="success" class="m-1" style="font-size: 0.8rem; font-weight: 500;">
                                    доступно к переливу <b-badge variant="light">{{ item.alternatableCount }}</b-badge>
                                </b-badge>
                            </td>
                            <td class="text-right">{{item.prev_count}}</td>
                            <td class="text-right">{{item.count}}</td>
                            <td class="text-right">
                                <i v-if="item.count < item.prev_count" class="fas fa-arrow-down" style="color: red;"></i>
                                <i v-else-if="item.count !== item.prev_count" class="fas fa-arrow-up" style="color: green;"></i>
                                <span>{{item.count - item.prev_count}}</span>
                            </td>
                        </tr>
                    </template>
                    <tr v-else>
                        <td colspan="4">
                            <div class="alert alert-info text-center mb-0">Нет данных</div>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td class="text-right">Итого:</td>
                        <td class="text-right">{{total.prev_count}}</td>
                        <td class="text-right">{{total.count}}</td>
                        <td class="text-right">
                            <i v-if="total.diff < 0" class="fas fa-arrow-down" style="color: red;"></i>
                            <i v-else-if="total.count !== total.prev_count" class="fas fa-arrow-up" style="color: green;"></i>
                            <span>{{total.diff}}</span>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import DateTimePicker from 'vue-ctk-date-time-picker';
    import {PRODUCTION_ORDERS_ENDPOINT} from "../../utils/endpoints";
    import {sortBy} from 'lodash';
    import moment from 'moment';
    import {sumBy} from 'lodash'
    import CSV from '../../utils/csv';

    export default {
        name: "AggregatedHistory",
        components: {DateTimePicker},
        props: {
            date: {
                required: true,
            }
        },
        data() {
            return {
                compareToDateTmp: null,
                compareToDate: null,
                loading: false,
                csvLoading: false,
                items: []
            };
        },
        computed: {
            maxDate() {
                return this.date + ' 20:00';
            },
            total() {
                return {
                    prev_count: sumBy(this.items, 'prev_count'),
                    count: sumBy(this.items, 'count'),
                    diff: sumBy(this.items, i => i.count - i.prev_count)
                }
            },
        },
        watch: {
            compareToDate: function(val) {
                this.load();
            }
        },
        methods: {
            moment,
            sumBy,
            load(csv = false) {
                if (csv && this.csvLoading) {
                    return;
                } else if (this.loading) {
                    return;
                }

                if (csv) {
                    this.csvLoading = true;
                } else {
                    this.loading = true;
                }
                this.$http.get(PRODUCTION_ORDERS_ENDPOINT + '/diff', {
                    params: {
                        date: this.date,
                        compare_to_date: this.compareToDate,
                        csv: csv ? 1 : 0,

                        without_loading: true,
                    },
                    before( request ) {
                        if (csv) {
                            return;
                        }
                        // abort previous request, if exists
                        if ( this.previousRequest ) {
                            this.previousRequest.abort();
                        }

                        // set previous request on Vue instance
                        this.previousRequest = request;
                    }
                }).then(response => {
                    if (csv) {
                        let name = 'Изменение заказа '+moment(this.date).format('DD.MM.YYYY')+' 20:00';
                        CSV.download(response.data, name);
                    } else {
                        this.items = response.data;
                    }
                }).catch(this.$root.responseError).finally(() => {
                    if (csv) {
                        this.csvLoading = false;
                    } else {
                        this.loading = false;
                    }
                });
            }
        },
        mounted() {
            this.load();
        }
    }
</script>

<style scoped>

</style>
