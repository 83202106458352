<template>
    <div>
        <div class="mb-2">Выберите основной план - к нему будут добавлены новые объемы</div>
        <table class="table table-sm table-striped">
            <tbody>
            <tr v-for="plan in plans" v-if="!mainId || plan.id === mainId">
                <td>
                    <b-checkbox :key="plan.id" @change="selectMain(plan, $event)"
                                :checked="mainSelected(plan.id)"></b-checkbox>
                </td>
                <td @click="selectMain(plan, !mainSelected(plan.id))">
                    <div v-for="ppmp in plan.sorted_markup_presets">
                        <div>{{name(ppmp)}}</div>
                    </div>
                </td>
                <td @click="selectMain(plan, !mainSelected(plan.id))">
                    <div v-for="ppmp in plan.sorted_markup_presets">
                        <div>{{ppmp.pivot.count}}</div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <template v-if="mainId">
            <div class="mb-2">Выберите планы для объединения с основным - они будут удалены</div>
            <table class="table table-sm table-striped">
                <tbody>
                <tr v-for="plan in disposablePlans()">
                    <td>
                        <b-checkbox :key="plan.id" @change="selectDisposable(plan, $event)"
                                    :checked="disposableSelected(plan.id)"></b-checkbox>
                    </td>
                    <td @click="selectDisposable(plan, !disposableSelected(plan.id))">
                        <div v-for="ppmp in plan.sorted_markup_presets">
                            {{name(ppmp)}}
                        </div>
                    </td>
                    <td @click="selectDisposable(plan, !disposableSelected(plan.id))">
                        <div v-for="ppmp in plan.sorted_markup_presets">
                            {{ppmp.pivot.count}}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="m-2">
                <button class="btn btn-success" :disabled="disposableIds.length === 0 || saving" @click="save()">
                    <i v-if="saving" class="fas fa-spinner fa-spin"></i>
                    Объединить
                </button>
            </div>
        </template>
    </div>
</template>

<script>
    import { findIndex } from 'lodash';
    import { PRODUCTION_PLANS_ENDPOINT } from "../../utils/endpoints";

    export default {
        name: "MergePlansDialog",
        props: {
            plans: {
                type: Array,
                required: true,
                default: () => []
            }
        },
        data() {
            return {
                mainId: null,
                disposableIds: [],
                saving: false
            }
        },
        watch: {
            mainId( val ) {
                if ( !val ) {
                    this.disposableIds = [];
                }
            },
        },
        methods: {
            name( markupPreset ) {
                return markupPreset.production_line_to_print + ' ' +
                    markupPreset.meal_day + ' ' +
                    this.day( markupPreset.day_of_week ) + ' ' +
                    markupPreset.city;
            },
            selectDisposable( plan, value ) {
                let index = findIndex( this.disposableIds, i => i === plan.id );
                if ( index !== -1 ) {
                    this.disposableIds.splice( index, 1 );
                }

                if ( value ) {
                    this.disposableIds.push( plan.id );
                }
            },
            disposableSelected( id ) {
                return findIndex( this.disposableIds, i => i === id ) !== -1;
            },
            selectMain( plan, value ) {
                this.mainId = null;
                if ( value ) {
                    this.mainId = plan.id;
                }
            },
            mainSelected( id ) {
                return this.mainId === id;
            },
            day( number ) {
                let days = {
                    1: 'ПН',
                    2: 'ВТ',
                    3: 'СР',
                    4: 'ЧТ',
                    5: 'ПТ',
                    6: 'СБ',
                    7: 'ВС'
                };

                return days[ number ];
            },
            disposablePlans() {
                let disposables = [];
                this.plans.map( ( plan, key ) => {
                    let p = this.plans[ key ];
                    if ( p.id !== this.mainId ) {
                        disposables.push( p );
                    }
                } );

                return disposables;
            },
            save() {
                if ( this.saving ) {
                    return;
                }

                this.saving = true;
                this.$http
                    .post( PRODUCTION_PLANS_ENDPOINT + '/merge', {
                        main_id: this.mainId,
                        disposable_ids: this.disposableIds
                    }, { params: { without_loading: true } } )
                    .then( response => {
                        this.$toast.success( 'Планы объединены. <button class="btn btn-sm" onclick="window.location.reload()">Обновите</button> страницу' );
                        this.$emit( 'success' );
                    } ).catch( response => this.$root.responseError(response, 'Ошибка объединения планов. Возможно, уже есть акты выпуска')).finally( () => {
                        this.saving = false;
                    } );
            }
        },
    }
</script>
