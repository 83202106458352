<template>
    <b-modal
        size='lg'
        :title='title'
        @hidden='hideModal()'
        ref='ProductionOrderShipmentModal'
        id='production-order-shipment-modal'
        scrollable
    >
        <table class="table table-responsive">
            <thead>
            <tr>
                <th>SKU</th>
                <th>СПБ</th>
                <th>МСК</th>
                <th>ННОВ</th>
                <th>Всего</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="shipment in shipments">
                <td>
                    <div>
                        {{shipment.sku}}
                        <b-badge v-show="shipment.showAlternatable && shipment.alternatableCount > 0" variant="success" style="font-size: 0.8rem; font-weight: 500;">
                            доступно к переливу <b-badge variant="light">{{ shipment.alternatableCount }}</b-badge>
                        </b-badge>
                    </div>
                    <div><small>{{shipment.lines.join(', ')}}</small></div>
                </td>
                <td>{{shipment.count_spb}}</td>
                <td>{{shipment.count_msk}}</td>
                <td>{{shipment.count_nnov}}</td>
                <td>{{shipment.total}}</td>
            </tr>
            </tbody>
        </table>

        <template #modal-footer>
            <div class='w-100 text-right'>
                <b-button variant='danger' @click='hideModal()'>Закрыть</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import forEach from 'lodash/forEach';

    const MSK_CITY = 'Москва';
    const SPB_CITY = 'Санкт-Петербург';
    const NNOV_CITY = 'Нижний Новгород';

    export default {
        name: "ProductionOrderShipmentModal",
        computed: {
            title() {
                return `Отгрузка на ` + this.deadline;
            }
        },
        data() {
            return {
                deadline: null,

                shipments: [],
            };
        },
        methods: {
            openModal( deadline, orders ) {
                this.deadline = deadline;
                this.shipments = this.generateShipmentInformation( orders );

                this.$refs.ProductionOrderShipmentModal.show();
            },
            hideModal() {
                this.$refs.ProductionOrderShipmentModal.hide();
                this.deadline = null;
                this.shipments = [];
            },

            generateShipmentInformation( orders ) {
                let firstOrder = orders[ 0 ];
                if ( !firstOrder ) {
                    return [];
                }

                let planShipments = [];
                let factShipments = [];
                forEach( firstOrder.nomenclatures, item => {
                    let factCounts = this.generateCountsRow();
                    let planCounts = this.generateCountsRow();

                    forEach( item.markup_presets, markupPreset => {
                        let countField = this.getCountField( markupPreset.markup_preset.city );
                        if ( !countField ) {
                            return true;
                        }

                        if ( markupPreset.factual_order !== null ) {
                            factCounts.total += parseInt( markupPreset.factual_order.count );
                            factCounts[ countField ] += parseInt( markupPreset.factual_order.count );

                            return true;
                        }

                        if ( markupPreset.planned_order !== null ) {
                            planCounts.total += parseInt( markupPreset.planned_order.count );
                            planCounts[ countField ] += parseInt( markupPreset.planned_order.count );
                        }
                    } );

                    let sku = {
                        sku: item.nomenclature.name,
                        lines: item.nomenclature.production_lines.map(l => l.code),
                        showAlternatable: item.showAlternatable,
                        alternatableCount: item.alternatableCount
                    };

                    if ( factCounts.total > 0 ) {
                        factShipments.push( { ...sku, ...factCounts } );
                    }

                    if ( planCounts.total > 0 ) {
                        planShipments.push( { ...sku, ...planCounts } );
                    }
                } );

                return factShipments.length > 0 ? factShipments : planShipments;
            },

            generateCountsRow() {
                return { count_spb: 0, count_msk: 0, count_nnov: 0, total: 0 };
            },

            getCountField( city ) {
                if ( city === MSK_CITY ) {
                    return 'count_msk';
                }

                if ( city === SPB_CITY ) {
                    return 'count_spb';
                }

                if (city === NNOV_CITY) {
                    return 'count_nnov';
                }

                return null;
            }
        }
    }
</script>
